import React, { Component } from 'react'
import $ from 'jquery';
import interact from 'interactjs';

class ImagesComposition extends Component {
  constructor(props) {
        super(props);
        
//        this.state = {};
        this.portaPrimoPiano = this.portaPrimoPiano.bind(this);
      this.mouseOut = this.mouseOut.bind(this);
    } 
  componentDidMount() {
      
    // target elements with the "draggable" class
    interact('.draggable')
      .draggable({
        // enable inertial throwing
//        inertia: false,
        // keep the element within the area of it's parent
        restrict: {
    //      restriction: "parent",
//          endOnly: true,
//          elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
        },
        // enable autoScroll
//        autoScroll: true,

        onstart:function (event) {
//            var target = event.target;
//            target.parentNode.removeChild(target); 
//            $("svg").append(target);
        },
        // call this function on every dragmove event
        onmove: dragMoveListener,
        // call this function on every dragend event
//        onend: function (event) {
//        }
    });

    function dragMoveListener (event) {
        var target = event.target,
            // keep the dragged position in the data-x/data-y attributes
            x = (parseFloat(target.getAttribute('data-x')) ||  (event.x0- event.clientX0)) + event.dx,
          y = (parseFloat(target.getAttribute('data-y')) || (event.y0- event.clientY0)) + event.dy;

        // translate the element
        target.style.webkitTransform =
        target.style.transform =
          'translate(' + x + 'px, ' + y + 'px)';

        // update the posiion attributes
        target.setAttribute('data-x', x);
        target.setAttribute('data-y', y);
      }

      // this is used later in the resizing and gesture demos
      window.dragMoveListener = dragMoveListener;   
}
    
portaPrimoPiano(i){
    var myGroup = $('svg #g_'+i ).detach();
    $("svg").append(myGroup);
    $('.indexContentContainer .imageComposition svg g #t_'+i).css('opacity','1');
}
    
mouseOut(i){
    $('.indexContentContainer .imageComposition svg g #t_'+i).css('opacity','0');
}
    
render() {
    return (  
    <div className='imageComposition'>    
        <svg>
        <g id='g_7' className='draggable' x='-1%' y="306">
         <image xlinkHref={this.props.images[7].image.url}
        x="-1%" y="306" height="161px" width="358px" preserveAspectRatio="xMidYMid slice" onMouseOver={() => {this.portaPrimoPiano(7)}} onMouseOut={() => {this.mouseOut(7)}} />
        <text id='t_7' x="0%" y="322" fontFamily="NeuzeitGrotesk, sans-serif" fontSize="12" fill="white">{this.props.images[7].name}</text>
        </g>
        
        <g id='g_4' className='draggable' x='59%' y="307.695">
        <image xlinkHref={this.props.images[4].image.url}
        x="59%" y="307.695" height="195px" width="297px" preserveAspectRatio="xMidYMid slice" onMouseOver={() => {this.portaPrimoPiano(4)}} onMouseOut={() => {this.mouseOut(4)}} />
        <text id='t_4' x="59.5%" y="323.695" fontFamily="NeuzeitGrotesk, sans-serif" fontSize="12" fill="white">{this.props.images[4].name}</text>
        </g>
        
        
        <g id='g_0' className='draggable' x='55%' y="16" >
         <image xlinkHref={this.props.images[0].image.url}
        x="55%" y="16" height="236px" width="380px" preserveAspectRatio="xMidYMid slice" onMouseOver={() => {this.portaPrimoPiano(0)}} onMouseOut={() => {this.mouseOut(0)}} />
        <text id='t_0' x="56%" y="32" fontFamily="NeuzeitGrotesk, sans-serif" fontSize="12" fill="white">{this.props.images[0].name}</text>
        </g>
        
        
        
         <g id='g_2' className='draggable' x='0' y="0">
        <image xlinkHref={this.props.images[2].image.url}
        x="0" y="0" height="331px" width="240px" preserveAspectRatio="xMidYMid slice" onMouseOver={() => {this.portaPrimoPiano(2)}} onMouseOut={() => {this.mouseOut(2)}} />
        <text id='t_2' x="1%" y="16" fontFamily="NeuzeitGrotesk, sans-serif" fontSize="12" fill="white">{this.props.images[2].name}</text>
        </g>
        
        <g id='g_5' className='draggable' x='18%' y="-6">
        <image xlinkHref={this.props.images[5].image.url}
        x="18%" y="-6" height="221px" width="328px" preserveAspectRatio="xMidYMid slice" onMouseOver={() => {this.portaPrimoPiano(5)}} onMouseOut={() => {this.mouseOut(5)}} />  
        <text id='t_5' x="18.5%" y="10" fontFamily="NeuzeitGrotesk, sans-serif" fontSize="12" fill="white">{this.props.images[5].name}</text>
        </g>
        
         <g id='g_3' className='draggable' x='12%' y="198">
        <image xlinkHref={this.props.images[3].image.url}
        x="12%" y="198" height="170px" width="368px" preserveAspectRatio="xMidYMid slice" onMouseOver={() => {this.portaPrimoPiano(3)}} onMouseOut={() => {this.mouseOut(3)}} />
        <text id='t_3' x="12.5%" y="210" fontFamily="NeuzeitGrotesk, sans-serif" fontSize="12" fill="white">{this.props.images[3].name}</text>
       </g>
        
         <g id='g_1' className='draggable' x='38%' y="122.588">
        <image xlinkHref={this.props.images[1].image.url}
        x="38%" y="122.588" height="320px" width="275px" preserveAspectRatio="xMidYMid slice" onMouseOver={() => {this.portaPrimoPiano(1)}} onMouseOut={() => {this.mouseOut(1)}} />
        <text id='t_1' x="39%" y="138.588" fontFamily="NeuzeitGrotesk, sans-serif" fontSize="12" fill="white">{this.props.images[1].name}</text>
        </g> 
        
         <g id='g_6' className='draggable' x='10%' y="340">
        <image xlinkHref={this.props.images[6].image.url}
        x="10%" y="340" height="241px" width="368px" preserveAspectRatio="xMidYMid slice" onMouseOver={() => {this.portaPrimoPiano(6)}} onMouseOut={() => {this.mouseOut(6)}} />
        <text id='t_6' x="10.5%" y="356" fontFamily="NeuzeitGrotesk, sans-serif" fontSize="12" fill="white">{this.props.images[6].name}</text>
        </g>
        
         <g id='g_8' className='draggable' x='38%' y="436">
        <image xlinkHref={this.props.images[8].image.url}
        x="38%" y="436" height="181px" width="348px" preserveAspectRatio="xMidYMid slice" onMouseOver={() => {this.portaPrimoPiano(8)}} onMouseOut={() => {this.mouseOut(8)}} />
        <text id='t_8' x="38.5%" y="452" fontFamily="NeuzeitGrotesk, sans-serif" fontSize="12" fill="white">{this.props.images[8].name}</text>
        </g>
        
        
        
        
         
        
</svg>   
    </div>
 );
  }//end render
}//end constructor

export default ImagesComposition;