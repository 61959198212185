import React, { Component }  from 'react'
import { withSiteData, RouteData } from 'react-static'
import { Link } from '@reach/router'
import ImagesComposition from '../containers/ImagesComposition';
import Letters from '../containers/Letters';
import $ from 'jquery';

function slugify(string) {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}


//export default withSiteData(() => (
class Index extends Component {
constructor(props) {
    super(props);
    
    this.state = { 
            isMobile_768: false,
            isMobile_h370: false
        };

                    
    this.throttledHandleWindowResize = this.throttledHandleWindowResize.bind(this);
  }
    
componentDidMount() {
    this.setState({isMobile_768: window.innerWidth < 844, isMobile_h370: window.innerHeight < 370});
    
    
    if(window.innerWidth < 844){
        $('.indexContentContainer .voicesContainer').addClass('mobile');
        $('.voices-list').css({'max-height':'auto','overflow': 'scroll'});
    }
    else{
        $('.indexContentContainer .voicesContainer').removeClass('mobile');
        $('.voices-list').css({'max-height':($( window ).height()-97)+'px','overflow': 'scroll'});
    }
        
   
     window.addEventListener('resize', this.throttledHandleWindowResize);
    
    $( ".voices-list" ).scroll(function() {
         
        $( ".VoicesList-with-submenu" ).each(function( index ) {
           var myName = $( this ).attr('name');
           var myTop = $( this ).position().top ;
             $('.VoicesList-submenu[name="'+myName+'"]').css('top', myTop);
        });
       
    });
}
    
throttledHandleWindowResize(){
    this.setState({isMobile_768: window.innerWidth < 844, isMobile_h370: window.innerHeight < 370, isMobile_w370: window.innerWidth < 370});
    if(window.innerWidth < 844){
        $('.indexContentContainer .voicesContainer').addClass('mobile');
        $('.voices-list').css({'max-height':'auto','overflow': 'scroll'});
    }
    else{
        $('.indexContentContainer .voicesContainer').removeClass('mobile');
        $('.voices-list').css({'max-height':($( window ).height()-97)+'px','overflow': 'scroll'});
    }
    
//    $('.voices-list').css({'max-height':($( window ).height()-97)+'px','overflow': 'scroll'});
}
render() {
  return (
  <RouteData>
    {({ voices, images4composition }) => (
        <div className='indexContentContainer'>
            <div className='voicesContainer'>
      {!this.state.isMobile_h370 && (<Letters voices={voices} page={'index'} />)}
                
                <div className='voices-list'>
                    {voices.map((voice, index) => {
                              return (
                                <div id={voice.cognome.toLowerCase().charAt(0)} className="VoicesList-post" key={voice.id} name={index}>
                            
                                {voice.interviews.length==1 &&(<Link  className="VoicesList-title" to={`/interview/${slugify(voice.interviews[0].title)}`}>{voice.nome} {voice.cognome}
                                  </Link>)}
                              

{voice.interviews.length>1 &&
<div className='VoicesList-with-submenu' name={index}>
<a  className="VoicesList-title" to='#'>{voice.nome} {voice.cognome}</a>
<div className='VoicesList-submenu' name={index}>
{voice.interviews.map((interview, index) => {
                              return (
                                <div className="VoicesList-submenu-item" key={interview.id} name={index}>
                        <Link  className="VoicesList-title VoicesList-submenu-item-title" to={`/interview/${slugify(interview.title)}`}>{interview.title} </Link>
                                </div>
                                );
                            })}
</div>
</div>
}

                                </div>
                              );
                            })}
                </div>
            </div>
           
{!this.state.isMobile_768 && (<ImagesComposition images={images4composition} />)}

            <div id='site-credits'>        
            <h6><a href="https://www.iubenda.com/privacy-policy/79237169" target='_blank'>Privacy Policy</a> - <a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/" target='_blank'>Questo sito e i suoi contenuti sono distribuiti con Licenza <img alt="Licenza Creative Commons"  src="https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png" /></a></h6>
            </div>
           
          </div>
             )}
  </RouteData>
);   
  }// end render
  
}// end component
           
export default withSiteData(() => (<Index />));

//Design&Dev: <a rel="license_design_and_dev" href="https://www.linkedin.com/in/viviana-paga-42bb8b44/" target='_blank' >Viv-s</a> / 


//<div className='letters'>
//                    <Link to='#a'>A</Link><Link to='#b'>B</Link><Link to='#c'>C</Link><Link to='#d'>D</Link><Link to='#e'>E</Link><Link to='#f'>F</Link><Link to='#g'>G</Link><Link to='#h'>H</Link><Link to='#i'>I</Link><Link to='#j'>J</Link><Link to='#k'>K</Link><Link to='#l'>L</Link><Link to='#m'>M</Link><Link to='#n'>N</Link><Link to='#o'>O</Link><Link to='#p'>P</Link><Link to='#q'>Q</Link><Link to='#r'>R</Link><Link to='#s'>S</Link><Link to='#t'>T</Link><Link to='#u'>U</Link><Link to='#v'>V</Link><Link to='#w'>W</Link><Link to='#x'>X</Link><Link to='#y'>Y</Link><Link to='#z'>Z</Link>
//                </div>





//))
//{JSON.stringify(voice)}
//{JSON.stringify(interview)}

//  {voice.interviews.length>1 &&(<Link  className="VoicesList-title" to={`/interview/${voice.interviews[0].id}`}>{voice.nome} {voice.cognome} ({voice.interviews.length})
//                                  </Link>)}
//
//                                {voice.interviews.length>1 &&
//(<div className='VoicesList-submenu'>xxx</div>)}
//                                
//                        {voice.interviews.length>1 && voice.interviews.map((interview, index) => {
//                              return (
//                                <div className="VoicesList-submenu-item" key={interview.id} name={index}>
//                        <Link  className="VoicesList-title VoicesList-submenu-item-title" to={`/interview/${interview.id}`}>{interview.title} </Link>
//                                </div>
//                                );
//                            })}